import Map from "ol/Map.js";
import View from "ol/View.js";
import { defaults as defaultControls, ScaleLine } from 'ol/control.js';
import TileLayer from "ol/layer/Tile.js";
import XYZ from "ol/source/XYZ.js";
import { Vector as VectorLayer } from "ol/layer.js";
import TileArcGISRest from "ol/source/TileArcGISRest";
import VectorSource from "ol/source/Vector.js";
import GeoJSON from "ol/format/GeoJSON.js";
import { Stroke, Style } from "ol/style.js";
import { toStringHDMS } from 'ol/coordinate';
import { toLonLat } from 'ol/proj';
import TileWMS from 'ol/source/TileWMS.js';
import { toPng } from 'html-to-image';
import Overlay from 'ol/Overlay';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import Static from 'ol/source/ImageStatic';


/*
const token = localStorage.getItem("auth-token");
import { fetchImage } from "/public/js/data/fetchImages";
const imageSelection = document.querySelector("#selectableContent");
console.log(fetchImage) */

/*
var layer1 = new ImageLayer({
    source: new ImageWMS({
        url: 'http://94.231.110.64:8080/geoserver/AAU_Setup/wms',
        params: {
            'LAYERS': 'AAU_Setup:example',
            'STYLES': 'point',
            'TILED': true
        },
        serverType: 'geoserver'
    })
});*/

function createwmsLayer(urlString, dataName) {
    var URL = urlString;
    var layer = dataName;

    var tilesource = new ImageWMS({
        url: URL,
        params: { 'LAYERS': layer, 'TILED': false },
        serverType: 'geoserver',
        transition: 0
    });
    //console.log(tilesource);
    return tilesource;
}

function createLayer(urlString) {
    var URL = urlString;

    let skibsvragSource = new VectorSource({
        format: new GeoJSON(),
        loader: function(extent) {
            var url = URL;
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            var onError = function() {
                skibsvragSource.removeLoadedExtent(extent);
            }
            xhr.onerror = onError;
            xhr.onload = function() {
                if (xhr.status == 200) {
                    skibsvragSource.addFeatures(
                        skibsvragSource.getFormat().readFeatures(xhr.responseText));
                } else {
                    onError();
                }
            }
            xhr.send();
        },
    });
    return skibsvragSource;
}

let backgroundmap = new TileLayer({
    id: "basemap",
    type: "base",
    title: "Basemap TOPO",
    source: new TileArcGISRest({
        url: "https://maps.helcom.fi/arcgis/rest/services/MADS/Basemap_TOPO/MapServer",
        crossOrigin: "Anonymous"
    }),
});

var scaleLineControl = new ScaleLine();

var view = new View({
    maxZoom: 18, //18
    center: [1951895.95429, 8379944.28496],
    zoom: 5, //6
});

const map = new Map({
    //layers: [backgroundmap, layer1], //ectorLight untiled, tiled
    target: "map",
    //overlays: [overlay], //this was on
    controls: defaultControls().extend([
        scaleLineControl
    ]),
    view: view,
});


function defineLayer(dataURL, dataID, dataName) {

    if (dataURL.includes("ows")) {
        var tileLayer = new VectorLayer({ //Now creating the layer on every function call, perhaps not good?
            source: createLayer(dataURL),
            title: dataID
        });
        return vectorLayer;
    }

    if (dataURL.includes("wms")) {
        var tileLayer = new ImageLayer({
            source: createwmsLayer(dataURL, dataName),
            title: dataID
        });
        return tileLayer;
    }
}


/**
 * Add a click handler to the map to render the popup. It works for popUP
 
map.on('singleclick', function(evt) {
    var coordinate = evt.coordinate;
    var hdms = toStringHDMS(toLonLat(coordinate));

    content.innerHTML = '<p>You clicked here:</p><code>' + hdms +
        '</code>';
    overlay.setPosition(coordinate);
}); */

/*// export options for html-to-image.
// See: https://github.com/bubkoo/html-to-image#options
var exportOptions = {
    filter: function (element) {
        return element.className ? element.className.indexOf('ol-control') === -1 : true;
    }
};

document.getElementById('export-png').addEventListener('click', function () {
    map.once('rendercomplete', function () {
        toPng(map.getTargetElement(), exportOptions)
            .then(function (dataURL) {
                var link = document.getElementById('image-download');
                link.href = dataURL;
                link.setAttribute ('crossOrigin','anonymous')
                link.crossorigin = "anonymous"
                link.click();
            });
    });
    map.renderSync();
});
*/

/*
// Pop uP
async function showPopUp() {
    const imagesServer = await fetchImage(token);
    console.log(imagesServer[0])

    var wmsSource = new ImageWMS({
        url: imagesServer[0].url,
        params: { 'LAYERS': imagesServer[0].layer, 'TILED': true },
        serverType: 'geoserver',
        //crossOrigin: 'anonymous',
    });

    var layer1 = new ImageLayer({
        source: wmsSource,
    });
    //map.addLayer(layer1)

    var element = document.getElementById('popup');
    var popup = new Overlay({
        element: element,
        positioning: 'bottom-center',
        stopEvent: false
    });
    map.addOverlay(popup);
    map.on('click', function(evt) {

        element.innerHTML = '';

        var feature = map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
            return feature;
        });

        if (feature) {
            var geometry = feature.getGeometry()
            var coord = feature.getGeometry().getCoordinates();
            var props = feature.getProperties();
            var info = "<h2>Not available</h2>";
            element.innerHTML = info;
            // Offset the popup so it points at the middle of the marker not the tip
            popup.setPosition(coord); //Offset([0, -22]);
            popup.show(coord, info);
        } else {
            //console.log(layer1)
            var url = wmsSource
                .getGetFeatureInfoUrl(
                    evt.coordinate,
                    map.getView().getResolution(),
                    map.getView().getProjection(), {
                        'INFO_FORMAT': 'application/json',
                        'propertyName': 'city'
                    }
                );

            if (imagesServer[0].url) {
                fetch(imagesServer[0].url)
                    .then(function(response) { return response.text(); })
                    .then(function(html) {
                        element.innerHTML = html;
                    });
            }

        };
    });

}
*/
//showPopUp();


const downloadMap = document.createElement("BUTTON"); //Create button for downloading the image of the image in current extent

downloadMap.innerHTML = '<i class="tiny material-icons">cloud_download</i>';
downloadMap.classList = "toolBtns";
downloadMap.addEventListener('click', function() {
    map.once('postcompose', function(event) {
        var canvas = event.context.canvas;
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(canvas.msToBlob(), 'map.png');
        } else {
            canvas.toBlob(function(blob) {
                saveAs(blob, 'map.png');
            });
        }
    });
    map.renderSync();
});

exports.addLayerToMap = function(dataID, dataURL, dataName) {
    let newLayerToggleSelector = document.querySelector(`#${dataID}`);
    const layer = defineLayer(dataURL, dataID, dataName);
    //const popupLayer = showPopUp(dataURL, dataID, dataName);

    newLayerToggleSelector.addEventListener("change", e => {
        if (newLayerToggleSelector.checked) return map.addLayer(layer);
        if (!newLayerToggleSelector.checked) return map.removeLayer(layer);
    })
};

exports.createMap = async function buildMap(curMap = map, background = backgroundmap) {
    curMap.addLayer(background); //backgroundmap
}