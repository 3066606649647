export async function fetchData(token) {
    const options = createOptions(token);
    const response = await fetch(
        //"http://localhost:1234/api/data/content",
        "https://balticrimdataportal.eu:3000/api/data/content", //{ mode: 'no-cors' },
        options
    );
    const data = await response.json();
    //console.log(options);
    return data;
}
//Managing post options
function createOptions(data = "") {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "auth-token": data
        }
    };
    return options;
}